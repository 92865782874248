import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Paris - Nice",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-06 18:03:09",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citroën Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Arkéa-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
    },
    riders: {
      1783: {
        id: 1783,
        startno: 153,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      2977: {
        id: 2977,
        startno: 16,
        firstName: "Ion",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-02-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 29,
          overallPosition: 29,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      2326: {
        id: 2326,
        startno: 175,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      75603: {
        id: 75603,
        startno: 54,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      38169: {
        id: 38169,
        startno: 135,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 68,
          overallPosition: 68,
          stageTime: "00:00:46",
          overallTime: "00:00:56",
        },
      },
      16583: {
        id: 16583,
        startno: 27,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      7640: {
        id: 7640,
        startno: 43,
        firstName: "Omar",
        lastName: "Fraile",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-17",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 67,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 8,
          overallPosition: 8,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      38164: {
        id: 38164,
        startno: 166,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      19784: {
        id: 19784,
        startno: 62,
        firstName: "Jack",
        lastName: "Haig",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-09-06",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 34,
          overallPosition: 34,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      70034: {
        id: 70034,
        startno: 117,
        firstName: "Mauri",
        lastName: "Vansevenant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-06-01",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 45,
          overallPosition: 45,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16752: {
        id: 16752,
        startno: 13,
        firstName: "Bryan",
        lastName: "Coquard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 18,
          overallPosition: 18,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      49662: {
        id: 49662,
        startno: 37,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
      },
      45368: {
        id: 45368,
        startno: 145,
        firstName: "Nils",
        lastName: "Eekhoff",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-01-23",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 14,
          overallPosition: 14,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      9302: {
        id: 9302,
        startno: 3,
        firstName: "Felix",
        lastName: "Grossschartner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-12-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "N",
      },
      2276: {
        id: 2276,
        startno: 31,
        firstName: "Nairo",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-02-04",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 41,
          overallPosition: 41,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      38923: {
        id: 38923,
        startno: 34,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-01",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 143,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      6557: {
        id: 6557,
        startno: 167,
        firstName: "Lukasz",
        lastName: "Wisniowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1991-12-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      9073: {
        id: 9073,
        startno: 186,
        firstName: "Loic",
        lastName: "Vliegen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-12-20",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 105,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 5,
          overallPosition: 5,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      27297: {
        id: 27297,
        startno: 211,
        firstName: "Franck",
        lastName: "Bonnamour",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-06-20",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 87,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      27210: {
        id: 27210,
        startno: 64,
        firstName: "Domen",
        lastName: "Novak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-07-24",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      63035: {
        id: 63035,
        startno: 155,
        firstName: "Sébastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      333: {
        id: 333,
        startno: 132,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      170: {
        id: 170,
        startno: 215,
        firstName: "Cyril",
        lastName: "Lemoine",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1983-03-03",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 72,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37494: {
        id: 37494,
        startno: 165,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 32,
          overallPosition: 32,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      7579: {
        id: 7579,
        startno: 197,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-04-19",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 65,
          overallPosition: 65,
          stageTime: "00:00:44",
          overallTime: "00:00:54",
        },
      },
      51244: {
        id: 51244,
        startno: 92,
        firstName: "Clément",
        lastName: "Champoussin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-29",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 46,
          overallPosition: 46,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      6242: {
        id: 6242,
        startno: 121,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 51,
          overallPosition: 51,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      730: {
        id: 730,
        startno: 23,
        firstName: "Steven",
        lastName: "Kruijswijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-06-07",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 61,
          overallPosition: 61,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      88703: {
        id: 88703,
        startno: 207,
        firstName: "Jay",
        lastName: "Vine",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-11-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      2146: {
        id: 2146,
        startno: 182,
        firstName: "Dimitri",
        lastName: "Claeys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-06-18",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
      },
      33063: {
        id: 33063,
        startno: 174,
        firstName: "Fabien",
        lastName: "Doubey",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-21",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 67,
          overallPosition: 67,
          stageTime: "00:00:46",
          overallTime: "00:00:56",
        },
      },
      9210: {
        id: 9210,
        startno: 17,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16807: {
        id: 16807,
        startno: 103,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37427: {
        id: 37427,
        startno: 93,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 52,
          overallPosition: 52,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      745: {
        id: 745,
        startno: 42,
        firstName: "Andrey",
        lastName: "Amador",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1986-08-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      37379: {
        id: 37379,
        startno: 75,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 33,
          overallPosition: 33,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16646: {
        id: 16646,
        startno: 4,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37281: {
        id: 37281,
        startno: 7,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 16,
          overallPosition: 16,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      27332: {
        id: 27332,
        startno: 152,
        firstName: "Steff",
        lastName: "Cras",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-13",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 36,
          overallPosition: 36,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      10807: {
        id: 10807,
        startno: 14,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      14902: {
        id: 14902,
        startno: 192,
        firstName: "Rudy",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "N",
      },
      48461: {
        id: 48461,
        startno: 51,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 13,
          overallPosition: 13,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16724: {
        id: 16724,
        startno: 73,
        firstName: "Stefan",
        lastName: "Küng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 26,
        firstName: "Wout",
        lastName: "van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:00",
          overallTime: "00:00:06",
        },
      },
      7011: {
        id: 7011,
        startno: 137,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      17182: {
        id: 17182,
        startno: 102,
        firstName: "Julien",
        lastName: "Bernard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      7730: {
        id: 7730,
        startno: 6,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 54,
          overallPosition: 54,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      48539: {
        id: 48539,
        startno: 81,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 26,
          overallPosition: 26,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      42186: {
        id: 42186,
        startno: 91,
        firstName: "Ben",
        lastName: "O'Connor",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-11-25",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 31,
          overallPosition: 31,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      1298: {
        id: 1298,
        startno: 112,
        firstName: "Iljo",
        lastName: "Keisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-12-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      27304: {
        id: 27304,
        startno: 97,
        firstName: "Damien",
        lastName: "Touzé",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 12,
          overallPosition: 12,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      20147: {
        id: 20147,
        startno: 41,
        firstName: "Adam",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 43,
          overallPosition: 43,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      31208: {
        id: 31208,
        startno: 142,
        firstName: "Cees",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-07-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      16576: {
        id: 16576,
        startno: 126,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      16620: {
        id: 16620,
        startno: 195,
        firstName: "James",
        lastName: "Piccoli",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-05",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      14716: {
        id: 14716,
        startno: 177,
        firstName: "Alexis",
        lastName: "Vuillermoz",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-06-01",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 48,
          overallPosition: 48,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16643: {
        id: 16643,
        startno: 1,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 55,
          overallPosition: 55,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      55141: {
        id: 55141,
        startno: 127,
        firstName: "Campbell",
        lastName: "Stewart",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-05-12",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      1987: {
        id: 1987,
        startno: 184,
        firstName: "Baptiste",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-09-28",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 141,
        firstName: "Søren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 38,
          overallPosition: 38,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      45620: {
        id: 45620,
        startno: 82,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-10-10",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      2338: {
        id: 2338,
        startno: 61,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "N",
      },
      6538: {
        id: 6538,
        startno: 86,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 42,
          overallPosition: 42,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      174: {
        id: 174,
        startno: 151,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      26: {
        id: 26,
        startno: 66,
        firstName: "Luis Le\u00c3\u00b3n",
        lastName: "Sanchez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-12-24",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 28,
          overallPosition: 28,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      49901: {
        id: 49901,
        startno: 213,
        firstName: "Victor",
        lastName: "Koretzky",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-08-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      37339: {
        id: 37339,
        startno: 57,
        firstName: "Yuriy",
        lastName: "Natarov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-12-28",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      16690: {
        id: 16690,
        startno: 12,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      20945: {
        id: 20945,
        startno: 107,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      63050: {
        id: 63050,
        startno: 35,
        firstName: "Matis",
        lastName: "Louvel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-07-19",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 156,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      37748: {
        id: 37748,
        startno: 111,
        firstName: "Fabio",
        lastName: "Jakobsen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-31",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      2322: {
        id: 2322,
        startno: 22,
        firstName: "Rohan",
        lastName: "Dennis",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-05-28",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37430: {
        id: 37430,
        startno: 204,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      45754: {
        id: 45754,
        startno: 173,
        firstName: "Mathieu",
        lastName: "Burgaudeau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-17",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 15,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 53,
          overallPosition: 53,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      66973: {
        id: 66973,
        startno: 146,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 30,
          overallPosition: 30,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      65447: {
        id: 65447,
        startno: 202,
        firstName: "Tobias",
        lastName: "Bayer",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1999-11-17",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      34624: {
        id: 34624,
        startno: 85,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 58,
          overallPosition: 58,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      10871: {
        id: 10871,
        startno: 74,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3277: {
        id: 3277,
        startno: 2,
        firstName: "Sam",
        lastName: "Bennett",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1990-10-16",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      44341: {
        id: 44341,
        startno: 52,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      920: {
        id: 920,
        startno: 164,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      92666: {
        id: 92666,
        startno: 181,
        firstName: "Biniam",
        lastName: "Girmay",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "2000-04-02",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 6,
          overallPosition: 6,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      32609: {
        id: 32609,
        startno: 214,
        firstName: "Jérémy",
        lastName: "Lecroq",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-04-07",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      46186: {
        id: 46186,
        startno: 216,
        firstName: "Luca",
        lastName: "Mozzato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-15",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 69,
          overallPosition: 69,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      46157: {
        id: 46157,
        startno: 161,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 63,
          overallPosition: 63,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      10971: {
        id: 10971,
        startno: 113,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      872: {
        id: 872,
        startno: 134,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 24,
          overallPosition: 24,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      3133: {
        id: 3133,
        startno: 36,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
      },
      929: {
        id: 929,
        startno: 65,
        firstName: "Wout",
        lastName: "Poels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-10-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 39,
          overallPosition: 39,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      82912: {
        id: 82912,
        startno: 147,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-10-16",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      20832: {
        id: 20832,
        startno: 205,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-07",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      27301: {
        id: 27301,
        startno: 96,
        firstName: "Aurélien",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-27",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 22,
          overallPosition: 22,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      2313: {
        id: 2313,
        startno: 46,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 47,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 50,
          overallPosition: 50,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      37447: {
        id: 37447,
        startno: 77,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 194,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 20,
          overallPosition: 20,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16637: {
        id: 16637,
        startno: 176,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 21,
          overallPosition: 21,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      18260: {
        id: 18260,
        startno: 5,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      75998: {
        id: 75998,
        startno: 83,
        firstName: "Finn",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2001-12-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      16581: {
        id: 16581,
        startno: 183,
        firstName: "Aimé",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
      },
      721: {
        id: 721,
        startno: 114,
        firstName: "Michael",
        lastName: "Mørkøv",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-04-30",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 24,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "03:48:38",
          overallTime: "03:48:28",
        },
      },
      1580: {
        id: 1580,
        startno: 55,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 21,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 2,
          overallPosition: 2,
          stageTime: "00:00:00",
          overallTime: "00:00:04",
        },
      },
      16144: {
        id: 16144,
        startno: 154,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      7590: {
        id: 7590,
        startno: 53,
        firstName: "David",
        lastName: "De La Cruz",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-05-06",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 27,
          overallPosition: 27,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      3044: {
        id: 3044,
        startno: 33,
        firstName: "Nicolas",
        lastName: "Edet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-12-03",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 56,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 203,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 66,
          overallPosition: 66,
          stageTime: "00:00:46",
          overallTime: "00:00:56",
        },
      },
      5059: {
        id: 5059,
        startno: 106,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 11,
          overallPosition: 11,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      346: {
        id: 346,
        startno: 185,
        firstName: "Rein",
        lastName: "Taaramäe",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 163,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      7720: {
        id: 7720,
        startno: 123,
        firstName: "Dylan",
        lastName: "Groenewegen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-06-21",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 122,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      37434: {
        id: 37434,
        startno: 217,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      10990: {
        id: 10990,
        startno: 11,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 23,
          overallPosition: 23,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      51252: {
        id: 51252,
        startno: 162,
        firstName: "Simon",
        lastName: "Carr",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-08-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      581: {
        id: 581,
        startno: 101,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 17,
          overallPosition: 17,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16578: {
        id: 16578,
        startno: 136,
        firstName: "Gregor",
        lastName: "Muhlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-04-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 35,
          overallPosition: 35,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      27124: {
        id: 27124,
        startno: 45,
        firstName: "Daniel",
        lastName: "Martinez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-04-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 47,
          overallPosition: 47,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      37378: {
        id: 37378,
        startno: 71,
        firstName: "David",
        lastName: "Gaudu",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-10",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16817: {
        id: 16817,
        startno: 144,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      50303: {
        id: 50303,
        startno: 84,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 40,
          overallPosition: 40,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      19254: {
        id: 19254,
        startno: 32,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21865,
        teamName: "Team Arkéa-Samsic",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 15,
          overallPosition: 15,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16615: {
        id: 16615,
        startno: 196,
        firstName: "Mads",
        lastName: "Würtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "N",
      },
      12800: {
        id: 12800,
        startno: 212,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 206,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 57,
          overallPosition: 57,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      37259: {
        id: 37259,
        startno: 63,
        firstName: "Gino",
        lastName: "Mäder",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 60,
          overallPosition: 60,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      39387: {
        id: 39387,
        startno: 157,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 133,
        firstName: "Ivan",
        lastName: "Garcia Cortina",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 7,
          overallPosition: 7,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      17203: {
        id: 17203,
        startno: 76,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 19,
          overallPosition: 19,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      8740: {
        id: 8740,
        startno: 104,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      68206: {
        id: 68206,
        startno: 131,
        firstName: "Matteo",
        lastName: "Jorgenson",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1999-07-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 25,
          overallPosition: 25,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      45363: {
        id: 45363,
        startno: 201,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 9,
          overallPosition: 9,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      37717: {
        id: 37717,
        startno: 94,
        firstName: "Dorian",
        lastName: "Godon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-25",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 62,
          overallPosition: 62,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      45349: {
        id: 45349,
        startno: 44,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-09-18",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 25,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37445: {
        id: 37445,
        startno: 124,
        firstName: "Lucas",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-12",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 193,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      15752: {
        id: 15752,
        startno: 171,
        firstName: "Pierre",
        lastName: "Latour",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-12",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 4,
          overallPosition: 4,
          stageTime: "00:00:19",
          overallTime: "00:00:29",
        },
      },
      38803: {
        id: 38803,
        startno: 187,
        firstName: "Georg",
        lastName: "Zimmermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-11",
        teamId: 21856,
        teamName: "Intermarché-Wanty-Gobert Matériaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 44,
          overallPosition: 44,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      23206: {
        id: 23206,
        startno: 191,
        firstName: "Carl Fredrik",
        lastName: "Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1991-09-26",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      22682: {
        id: 22682,
        startno: 95,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 56,
          overallPosition: 56,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
      4669: {
        id: 4669,
        startno: 116,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 49,
          overallPosition: 49,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      12481: {
        id: 12481,
        startno: 115,
        firstName: "Florian",
        lastName: "Sénéchal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 10,
          overallPosition: 10,
          stageTime: "00:00:22",
          overallTime: "00:00:32",
        },
      },
      16823: {
        id: 16823,
        startno: 172,
        firstName: "Niccolo",
        lastName: "Bonifazio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 125,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 59,
          overallPosition: 59,
          stageTime: "00:00:36",
          overallTime: "00:00:46",
        },
      },
    },
  }

  const race = "Paris Nice"
  const raceID = 2

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
